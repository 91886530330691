import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import Satchel, { DisplayState } from "satchel-ts";
import {
  ROUTES__ONBOARDING,
  ROUTES__ONBOARDING_WELCOME
} from "../../constants";
import {
  getFromLocalStorage,
  persistToLocalStorage
} from "../../utils/localStorage";

type ContextValue = {
  satchel: Satchel;
  completedOnboarding: boolean;
  recoverWallet: (seedPhrase: string) => Promise<void>;
  createNewWallet: () => Promise<void>;
  logout: () => void;
};

const SatchelContext = React.createContext<ContextValue | undefined>(undefined);

const SatchelProvider = (props: any) => {
  const history = useHistory();
  const satchel = useRef<Satchel>();
  const [displayState, setDisplayState] = useState<DisplayState>();

  const completedOnboarding = useMemo(() => !!displayState, [displayState]);

  useEffect(() => {
    const initialVault = getFromLocalStorage(SATCHEL_VAULT_STORAGE_KEY, null);
    satchel.current = initialVault
      ? new Satchel({ initialVault })
      : new Satchel();

    satchel.current.vault.subscribe(persistVault);
    satchel.current.displayState.subscribe(setDisplayState);

    return () => {
      satchel.current?.vault.unsubscribe(persistVault);
      satchel.current?.displayState.unsubscribe(setDisplayState);
    };
  }, []);

  React.useEffect(() => {
    console.log({ displayState });
  }, [displayState]);

  useEffect(() => {
    console.log(satchel.current?.vault.getState().encryptedVaultWallet);
    if (!satchel.current?.vault.getState().encryptedVaultWallet) {
      console.log("not logged in -> redirect to initialize page");
      history.push(`${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_WELCOME}`);
    }
  }, [history]);

  // const recoverWallet = useCallback(
  //   async (seedPhrase: string) => {
  //     if (!seedPhrase) {
  //       return;
  //     }

  //     await satchel.login(seedPhrase);

  //     if (satchel.isLoggedIn()) {
  //       history.push(ROUTES__WALLET);
  //     } else {
  //       throw new Error("Could not recover wallet based on provided mnemonic");
  //     }
  //   },
  //   [history]
  // );

  // const createNewWallet = useCallback(async () => {
  //   if (satchel.isLoggedIn()) {
  //     alert("already logged in");
  //   } else {
  //     await satchel.new();
  //     history.push(ROUTES__WALLET);
  //   }
  // }, [history]);

  const logout = useCallback(() => {
    if (completedOnboarding) {
      satchel.current?.lockWallet();
      history.push(ROUTES__ONBOARDING);
    }
  }, [completedOnboarding, history]);

  const value = useMemo(
    () => ({
      satchel,
      completedOnboarding,
      recoverWallet: () => {},
      createNewWallet: () => {},
      logout
    }),
    [completedOnboarding, logout]
  );

  return <SatchelContext.Provider value={value} {...props} />;
};

const useSatchel = (): ContextValue => {
  const context = useContext(SatchelContext);
  if (context === undefined) {
    throw new Error("useSatchel must be used within an SatchelProvider");
  }
  return context;
};

export { SatchelProvider, useSatchel };

//
// Utils
//

const SATCHEL_VAULT_STORAGE_KEY = "satchel-vault";

const persistVault = (updatedVault: any) => {
  console.log(updatedVault);
  persistToLocalStorage(SATCHEL_VAULT_STORAGE_KEY, updatedVault);
};
