import { Container, Toolbar } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import Header from "./components/Header";
import AppProviders from "./context";
import AppRoutes from "./pages/Routes";

ReactDOM.render(
  <AppProviders>
    <Header />
    <Toolbar variant="dense" />
    <Container maxWidth="md">
      <AppRoutes />
    </Container>
  </AppProviders>,
  document.getElementById("app-content")
);
