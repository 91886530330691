import blue from "@material-ui/core/colors/blue";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: blue
  }
});

export default theme;
