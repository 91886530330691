import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router-dom";
import { ROUTES__ONBOARDING } from "../../constants";
import { useSatchel } from "../../context/satchel/SatchelProvider";

const Wallet = () => {
  const { completedOnboarding } = useSatchel();

  if (!completedOnboarding) {
    return <Redirect to={ROUTES__ONBOARDING} />;
  }

  const balanceInBsv = 0; // satchel.balance() * SATOSHI_IN_BSV;

  const address = "not set"; //satchel.address().toString()

  return (
    <List>
      <ListItem>
        <ListItemText primary="Address" secondary={address} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Balance"
          secondary={`${balanceInBsv.toFixed(4)} BSV`}
        />
      </ListItem>
    </List>
  );
};

export default Wallet;

//
// Utils
//

// const SATOSHI_IN_BSV = 0.00000001;
