import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import React from "react";
import { HashRouter } from "react-router-dom";
import theme from "../theme";
import { ExtensionProvider } from "./extension/ExtensionProvider";
import { SatchelProvider } from "./satchel/SatchelProvider";

const AppProviders = ({ children }: any) => (
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <StylesProvider injectFirst>
      <HashRouter hashType="noslash">
        <ExtensionProvider>
          <SatchelProvider>{children}</SatchelProvider>
        </ExtensionProvider>
      </HashRouter>
    </StylesProvider>
  </ThemeProvider>
);

export default AppProviders;
