import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES__ONBOARDING, ROUTES__WALLET } from "../constants";
import OnboardingRoutes from "./onboarding/Routes";
import Wallet from "./wallet/Wallet";

const AppRoutes = () => (
  <Switch>
    <Route path={ROUTES__ONBOARDING} component={OnboardingRoutes} />
    <Route path={ROUTES__WALLET} component={Wallet} />
    <Redirect to={ROUTES__WALLET} />
  </Switch>
);

export default AppRoutes;
