import React, { useRef } from "react";
import { Redirect, Route, Switch } from "react-router";
import {
  ROUTES__ONBOARDING,
  ROUTES__ONBOARDING_CREATE_WALLET,
  ROUTES__ONBOARDING_RECOVER_WALLET,
  ROUTES__ONBOARDING_WELCOME,
  ROUTES__WALLET
} from "../../constants";
import { useExtension } from "../../context/extension/ExtensionProvider";
import { useSatchel } from "../../context/satchel/SatchelProvider";
import { EnvironmentType } from "../../enums";
import { getEnvironmentType } from "../../utils/utils";
import CreateWallet from "./CreateWallet";
import RecoverWallet from "./RecoverWallet";
import Welcome from "./Welcome";

const OnboardingRoutes = () => {
  const { completedOnboarding } = useSatchel();
  const { openExtensionInBrowser } = useExtension();
  const isOpenedInFullscreen = useRef<boolean>(false);

  if (
    !isOpenedInFullscreen.current &&
    getEnvironmentType() !== EnvironmentType.ENVIRONMENT_TYPE_FULLSCREEN
  ) {
    isOpenedInFullscreen.current = true;
    openExtensionInBrowser(ROUTES__ONBOARDING);
  }

  if (completedOnboarding) {
    return <Redirect to={ROUTES__WALLET} />;
  }

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_WELCOME}`}
        component={Welcome}
      />
      <Route
        exact
        path={`${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_CREATE_WALLET}`}
        component={CreateWallet}
      />
      <Route
        exact
        path={`${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_RECOVER_WALLET}`}
        component={RecoverWallet}
      />
      <Redirect to={`${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_WELCOME}`} />
    </Switch>
  );
};

export default OnboardingRoutes;
