import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ROUTES__ONBOARDING,
  ROUTES__ONBOARDING_CREATE_WALLET,
  ROUTES__ONBOARDING_RECOVER_WALLET
} from "../../constants";
import { getRoutePath } from "../../utils/utils";

const Welcome = () => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const classes = useStyles();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <h1 className={classes.title}>Welcome to Satchel Wallet</h1>
      <p className={classes.welcomeText}>
        Satchel Wallet is a safe Bitcoin SV wallet that seamlessly integrates
        with many dApps across the internet.
      </p>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          variant="contained"
          color="primary"
          href={getRoutePath(
            `${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_CREATE_WALLET}`
          )}
        >
          Create your wallet
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select other wallet creation options"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    onClick={() => {
                      history.push(
                        `${ROUTES__ONBOARDING}${ROUTES__ONBOARDING_RECOVER_WALLET}`
                      );
                    }}
                  >
                    Recover from seed phrase
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Paper>
  );
};

export default Welcome;

//
// Utils
//

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(4)
  },
  title: {
    marginTop: 0
  },
  welcomeText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
}));
