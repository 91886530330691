import BrowserPassworder from 'browser-passworder';
import { HDPrivateKey, HDPublicKey, Mnemonic } from 'bsv';
import { EventEmitter } from 'events';
import ObservableStore from 'obs-store';

interface Encryptor {
  encrypt: (password: string, data: any) => Promise<string>;
  decrypt: (password: string, text: string) => Promise<any>;
}

interface Vault {
  encryptedVaultWallet?: string;
}

export interface DisplayState {
  isUnlocked: boolean;
  accounts: DisplayAccount[];
}

interface WalletAccount {
  title: string; // This can be updated by the user. TODO: provide a method for doing that
  accountNumber: number; // The last index of the path within the HD Wallet (e.g. 0 for m/0/0, 1 for m/0/1, ...)
}

interface DisplayAccount extends WalletAccount {
  address: string;
  qrCodeImgSrc: string;
}

interface VaultWallet {
  seedPhrase: string;
  accounts: WalletAccount[];
  nextPathIndex: number; // This keeps track of the next available path index, which is needed because accounts can be removed.
}

interface Wallet extends VaultWallet {
  hdPrivateKey: HDPrivateKey;
  privateKey: string;
  hdPublicKey: HDPublicKey;
  publicKey: string;
}

enum EventTypes {
  UPDATE = 'update',
  ADDED_ACCOUNT = 'addedAccount',
  REMOVED_ACCOUNT = 'removedAccount',
  RENAMED_ACCOUNT = 'renamedAccount'
}

// BIP44 path structure: m / purpose' / coin_type' / account' / change / address_index
const BIP44_PURPOSE = 44;
const BIP44_COIN_TYPE_INDEX_BSV = 239;

interface SatchelConfig {
  initialVault?: Vault;
  encryptor?: Encryptor;
}

class Satchel extends EventEmitter {
  vault: ObservableStore<Vault>;
  displayState: ObservableStore<DisplayState>;
  encryptor: Encryptor;
  password: string | null;
  wallet: Wallet | null;

  constructor(config?: SatchelConfig) {
    super();

    const { initialVault = {}, encryptor = BrowserPassworder } = config || {};

    // The vault holds the encrypted accounts data, which could be persisted externally (e.g. localStorage)
    // and initialised with initialVault when instantiating the class.
    this.vault = new ObservableStore<Vault>(initialVault);

    // The displayState is what can be used by the app to render the state of the wallet (accounts, locked, ...).
    // There are events emitted on every update which facilitate this.
    this.displayState = new ObservableStore<DisplayState>({
      isUnlocked: false,
      accounts: []
    });

    // The mechanism of encryption used by the vault.
    // By default 'browser-passworder' is used but a custom implementation can be configured
    this.encryptor = encryptor;

    // Password that's used for encrypting the wallet data beign persisted in the vault.
    // It's provided by the user when unlocking the wallet, and assigned to this variable on success.
    this.password = null;

    // This contains data necessary for the wallet to be functional.
    // By loading the class with initialVault, containing the encrypted version of this data,
    // it will be decrypted and made available by unlocking the wallet using the correct password
    this.wallet = null;
  }

  // Suggested UX flow when creating a new wallet:
  // Generate a seed prhase,
  // test the user that they backed it up,
  // ask them for a password (with two inputs to check if they're identical),
  // create a new wallet and account using the seed phrase and password.
  generateSeedPhrase = (): string => {
    let mnemonic = Mnemonic.fromRandom();
    return mnemonic.toString();
  };

  // Destroys any old encrypted storage,
  // creates a new encrypted store with the given password,
  // creates a new HD wallet from the given seed phrase with 1 account.
  // Note that when restoring an existing HD wallet, we assume that it was created with Satchel
  // because of the accounts path structure (not BIP 44).
  createWallet = async (
    password: string,
    seedPhrase: string
  ): Promise<DisplayState> => {
    if (typeof password !== 'string') {
      return Promise.reject('Password must be text.');
    } else if (password === '') {
      return Promise.reject("Password can't be empty.");
    } else if (typeof seedPhrase !== 'string') {
      return Promise.reject('Seed phrase must be text.');
    } else if (!Mnemonic.isValid(seedPhrase)) {
      return Promise.reject('Seed phrase is invalid.');
    }

    this.password = password;

    this.wallet = this._getCompleteWallet({
      seedPhrase,
      accounts: [this._generateAccountForPathIndex(0)],
      nextPathIndex: 1
    });

    // TODO: Verify if 'm/0/0' ever had activity. If so, crawl the next 100 paths.
    // Based on that, restore the accounts that had activity and update the nextAccountPath

    await this._updateVault();

    await this._updateDisplayAccounts();

    this.displayState.updateState({ isUnlocked: true });

    return this._broadcastDisplayState();
  };

  // Attempts to decrypt the persisted encrypted storage,
  // stores the password into memory,
  // reconstructs the wallet and stores it into memory,
  // initializes and broadcasts the display accounts
  unlockWallet = async (password: string): Promise<DisplayState> => {
    if (typeof password !== 'string') {
      return Promise.reject('Password must be text.');
    } else if (password === '') {
      return Promise.reject("Password can't be empty.");
    }

    const { encryptedVaultWallet } = this.vault.getState();
    if (encryptedVaultWallet == null) {
      return Promise.reject('Cannot unlock without a previous vault.');
    }

    let vaultWallet: VaultWallet;

    try {
      vaultWallet = await this.encryptor.decrypt(
        password,
        encryptedVaultWallet
      );
    } catch (e) {
      return Promise.reject('Incorrect password');
    }

    this.password = password;

    this.wallet = this._getCompleteWallet(vaultWallet);

    await this._updateDisplayAccounts();

    this.displayState.updateState({ isUnlocked: true });
    return this._broadcastDisplayState();
  };

  // Clear all secrets from memory.
  lockWallet = async () => {
    this.password = null;

    this.wallet = null;

    this.displayState.putState({ isUnlocked: false, accounts: [] });

    return this._broadcastDisplayState();
  };

  addAccount = async (): Promise<DisplayState> => {
    if (this.wallet == null) {
      return Promise.reject(
        'The wallet needs to be available before adding a new account'
      );
    }

    const nextIndex = this.wallet.nextPathIndex;

    this.wallet.accounts = [
      ...this.wallet.accounts,
      this._generateAccountForPathIndex(nextIndex)
    ];

    this.wallet.nextPathIndex = this.wallet.nextPathIndex + 1;

    await this._updateVault();

    await this._updateDisplayAccounts();

    const displayAccounts = this.displayState.getState().accounts;
    this.emit(
      EventTypes.ADDED_ACCOUNT,
      displayAccounts[displayAccounts.length - 1]
    );

    return this._broadcastDisplayState();
  };

  removeAccount = async (accountNumber: number): Promise<DisplayState> => {
    if (this.wallet == null) {
      return Promise.reject(
        'The wallet needs to be available before removing a new account'
      );
    } else if (accountNumber === 0) {
      return Promise.reject("You can't remove the first account.");
    }

    const accountToRemove = this.wallet.accounts.find(
      account => account.accountNumber === accountNumber
    );

    if (accountToRemove == null) {
      return Promise.reject("The account to be removed can't be found.");
    }

    this.wallet.accounts = this.wallet.accounts.filter(
      account => account.accountNumber !== accountNumber
    );

    await this._updateVault();

    const displayAccountToRemove = this.displayState
      .getState()
      .accounts.find(account => account.accountNumber === accountNumber);

    await this._updateDisplayAccounts();

    this.emit(EventTypes.REMOVED_ACCOUNT, displayAccountToRemove);

    return this._broadcastDisplayState();
  };

  renameAccount = async (
    accountNumber: number,
    newTitle: string
  ): Promise<DisplayState> => {
    if (this.wallet == null) {
      return Promise.reject(
        'The wallet needs to be available before renaming an account'
      );
    } else if (typeof newTitle !== 'string') {
      return Promise.reject('Account title must be text.');
    } else if (newTitle === '') {
      return Promise.reject("Account title can't be empty.");
    }

    this.wallet.accounts = this.wallet.accounts.map(account =>
      account.accountNumber === accountNumber
        ? {
            ...account,
            title: newTitle
          }
        : account
    );

    await this._updateVault();

    await this._updateDisplayAccounts();

    const renamedDisplayAccount = this.displayState
      .getState()
      .accounts.find(account => account.accountNumber === accountNumber);

    this.emit(EventTypes.RENAMED_ACCOUNT, renamedDisplayAccount);

    return this._broadcastDisplayState();
  };

  // All methods below are supposed to be private, however this is not supported in es5 (the target of of this lib).
  // Private class fields are currently a TC39 stage 3 candidate and will likely be added to ES2019 (ES10).
  // Private fields are currently supported in Node.js 12, Chrome 74, and Babel.

  _getCompleteWallet = (vaultWallet: VaultWallet): Wallet => {
    const mnemonic = Mnemonic.fromString(vaultWallet.seedPhrase);
    const hdPrivateKey = HDPrivateKey.fromSeed(mnemonic.toSeed(), 'livenet');
    const privateKey = hdPrivateKey.toString(); // TODO: really needed?
    const hdPublicKey = HDPublicKey.fromHDPrivateKey(hdPrivateKey); // TODO: really needed?
    const publicKey = hdPublicKey.toString(); // TODO: really needed?
    return {
      ...vaultWallet,
      hdPrivateKey,
      privateKey,
      hdPublicKey,
      publicKey
    };
  };

  _generateAccountForPathIndex = (accountNumber: number): WalletAccount => ({
    title: `Account ${accountNumber + 1}`,
    accountNumber: accountNumber
  });

  // Encrypts the wallet with the provided password,
  // and persists that encrypted string to storage.
  _updateVault = async (
    password: string | undefined | null = this.password
  ): Promise<boolean> => {
    if (typeof password !== 'string') {
      return Promise.reject('Password must be text.');
    } else if (password === '') {
      return Promise.reject("Password can't be empty.");
    }

    if (this.wallet == null) {
      // TODO: double check use cases. I would imagine that in these cases, _clearWallet would be used
      this.vault.putState({});
      return Promise.resolve(true);
    }

    const { seedPhrase, accounts, nextPathIndex }: VaultWallet = this.wallet;

    return this.encryptor
      .encrypt(this.password as string, { seedPhrase, accounts, nextPathIndex })
      .then(encryptedVaultWallet => {
        this.vault.putState({ encryptedVaultWallet });
        return true;
      });
  };

  _getQrCodeImgSrc = (
    address: string,
    size: number = 132,
    format: string = 'svg'
  ) =>
    `https://api.qrserver.com/v1/create-qr-code/?&qzone=1&data=${address}&size=${size}x${size}&format=${format}`;

  _updateDisplayAccounts = async () => {
    const wallet = this.wallet; // To bypass TS limitation: https://github.com/Microsoft/TypeScript/issues/31060#issuecomment-485621164

    if (wallet == null) {
      return;
    }

    const displayAccounts: DisplayAccount[] = await Promise.all(
      wallet.accounts.map(async walletAccount => {
        // const accountPrivateKey = wallet.hdPrivateKey.deriveChild(
        //   `m/0/${walletAccount.accountNumber}`
        // ).privateKey;
        const hdPrivateKey = wallet.hdPrivateKey.deriveChild(
          `m/${BIP44_PURPOSE}'/${BIP44_COIN_TYPE_INDEX_BSV}'/${walletAccount.accountNumber}'`
        );
        const hdPublicKey = HDPublicKey.fromHDPrivateKey(hdPrivateKey);

        const response = await fetch(
          `https://bchsvexplorer.com/api/v2/xpub/${hdPublicKey.toString()}`
        );

        console.log(response);

        const address = (response as any).address;

        // const accountPrivateKey = wallet.hdPrivateKey.deriveChild(
        //   `m/${BIP44_PURPOSE}'/${BIP44_COIN_TYPE_INDEX_BSV}'/${walletAccount.accountNumber}/${response.next_num}`
        // ).privateKey;

        // response.next_address is what's below here

        // const address = Address.fromPrivateKey(accountPrivateKey).toString();
        return {
          ...walletAccount,
          address,
          qrCodeImgSrc: this._getQrCodeImgSrc(address, 132, 'svg')
        };
      })
    );

    this.displayState.updateState({ accounts: displayAccounts });
  };

  // Emits the EventTypes.UPDATE event and returns the current memStore
  _broadcastDisplayState = (): DisplayState => {
    this.emit(EventTypes.UPDATE, this.displayState.getState());
    return this.displayState.getState();
  };
}

export default Satchel;
