export enum EnvironmentType {
  ENVIRONMENT_TYPE_POPUP = "popup",
  ENVIRONMENT_TYPE_NOTIFICATION = "notification",
  ENVIRONMENT_TYPE_FULLSCREEN = "fullscreen",
  ENVIRONMENT_TYPE_BACKGROUND = "background"
}

export enum Platform {
  PLATFORM_BRAVE = "Brave",
  PLATFORM_CHROME = "Chrome",
  PLATFORM_EDGE = "Edge",
  PLATFORM_FIREFOX = "Firefox",
  PLATFORM_OPERA = "Opera"
}
