import { AppBar, Box, Button, Container, Toolbar } from "@material-ui/core";
import React from "react";
import { useSatchel } from "../context/satchel/SatchelProvider";
import HideOnScroll from "../utils/HideOnScroll";

const Header = () => {
  const { completedOnboarding, logout } = useSatchel();

  console.log("Header");

  return (
    <HideOnScroll>
      <AppBar color="inherit">
        <Toolbar variant="dense">
          <Container maxWidth="md">
            <Box display="flex">
              <Box fontSize="h6.fontSize" flexGrow={1}>
                Satchel Wallet
              </Box>
              <Box>
                {completedOnboarding && (
                  <Button size="small" onClick={logout}>
                    Logout
                  </Button>
                )}
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default Header;
