// Persist data to localStorage
export const persistToLocalStorage = (storageKey: string, data: Object) => {
  if (window.localStorage) {
    try {
      window.localStorage.setItem(storageKey, JSON.stringify(data));
    } catch (e) {
      console.error("Failed saving data to LocalStorage");
    }
  }
};

// Clear data to localStorage
export const clearFromLocalStorage = (storageKey: string) => {
  if (window.localStorage) {
    try {
      window.localStorage.clearItem(storageKey);
    } catch (e) {
      console.error("Failed clearing data from LocalStorage");
    }
  }
};

// Get data from localStorage
export const getFromLocalStorage = (storageKey: string, defaultData: any) => {
  let data = defaultData;
  if (window.localStorage) {
    try {
      const storedData = window.localStorage.getItem(storageKey);
      data = storedData ? JSON.parse(storedData) : defaultData;
    } catch (e) {
      console.error("Failed to retrieve data to LocalStorage");
    }
  }
  return data;
};
