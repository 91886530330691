import { Button, FormControl, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useState } from "react";
import { useSatchel } from "../../context/satchel/SatchelProvider";

const CreateOrRecoverWallet = () => {
  const [showSeedPhraseForm, setShowSeedPhraseForm] = useState<boolean>(false);
  const [seedPhrase, setSeedPhrase] = useState("");
  const [formError, setFormError] = useState<string>("");
  const classes = useStyles();

  const { recoverWallet, createNewWallet } = useSatchel();

  const handleSubmit = useCallback(
    async _event => {
      try {
        await recoverWallet(seedPhrase);
      } catch (e) {
        console.log(e);
        setFormError(e.message);
      }
    },
    [recoverWallet, seedPhrase]
  );
  const toggleSeedPhraseForm = useCallback(
    () => setShowSeedPhraseForm(x => !x),
    []
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h1>No, I already have a seed phrase</h1>
            <p>Import your existing wallet using a 12 word seed phrase</p>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleSeedPhraseForm}
              disabled={showSeedPhraseForm}
            >
              Import Wallet
            </Button>
            {showSeedPhraseForm && (
              <form noValidate autoComplete="off">
                <FormControl fullWidth>
                  <TextField
                    label="Seed phrase"
                    className={classes.seedPhraseInput}
                    multiline
                    value={seedPhrase}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSeedPhrase(event.target.value);
                    }}
                    rows="2"
                    autoFocus
                    error={!!formError}
                    helperText={formError}
                    variant="outlined"
                  />
                </FormControl>
                <div className={classes.submitButtons}>
                  <Button onClick={() => setShowSeedPhraseForm(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h1>Yes, let’s get set up!</h1>
            <p>This will create a new wallet and seed phrase.</p>
            <Button
              variant="contained"
              color="primary"
              onClick={createNewWallet}
            >
              Create a Wallet
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateOrRecoverWallet;

//
// Utils
//

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2)
  },
  seedPhraseInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  submitButtons: {
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1)
    },
    textAlign: "right"
  }
}));
