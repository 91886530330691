import * as extension from "extensionizer";
import { EnvironmentType, Platform } from "../enums";

export const getRoutePath = (route: string): string => {
  const parsedUrl = new URL(window.location.href);
  return `${parsedUrl.origin}${parsedUrl.pathname}#${route}`;
};

/**
 * Used to determine the window type through which the app is being viewed.
 *  - 'popup' refers to the extension opened through the browser app icon (in top right corner in chrome and firefox)
 *  - 'fullscreen' refers to the main browser window
 *  - 'notification' refers to the popup that appears in its own window when taking action outside of metamask
 *  - 'background' refers to the background page
 *
 * @returns {EnvironmentType} - A single word label that represents the type of window through which the app is being viewed
 *
 */
export const getEnvironmentType = (
  url: string = window.location.href
): EnvironmentType => {
  const parsedUrl = new URL(url);
  if (parsedUrl.pathname === "/popup.html") {
    return EnvironmentType.ENVIRONMENT_TYPE_POPUP;
  } else if (parsedUrl.pathname === "/home.html") {
    return EnvironmentType.ENVIRONMENT_TYPE_FULLSCREEN;
  } else if (parsedUrl.pathname === "/notification.html") {
    return EnvironmentType.ENVIRONMENT_TYPE_NOTIFICATION;
  } else {
    return EnvironmentType.ENVIRONMENT_TYPE_BACKGROUND;
  }
};

/**
 * Returns the platform (browser) where the extension is running.
 *
 * @returns {Platform} - the platform ENUM
 *
 */
export const getPlatform = (): Platform => {
  const ua = navigator.userAgent;
  if (ua.search("Firefox") !== -1) {
    return Platform.PLATFORM_FIREFOX;
  } else {
    if (
      window &&
      (window as any).chrome &&
      (window as any).chrome.ipcRenderer
    ) {
      return Platform.PLATFORM_BRAVE;
    } else if (ua.search("Edge") !== -1) {
      return Platform.PLATFORM_EDGE;
    } else if (ua.search("OPR") !== -1) {
      return Platform.PLATFORM_OPERA;
    } else {
      return Platform.PLATFORM_CHROME;
    }
  }
};

/**
 * Returns an Error if extension.runtime.lastError is present
 * this is a workaround for the non-standard error object thats used
 * @returns {Error}
 */
export const checkForError = (): Error | void => {
  const lastError = extension.runtime.lastError;
  if (!lastError) {
    return;
  }
  // if it quacks like an Error, its an Error
  if (lastError.stack && lastError.message) {
    return lastError;
  }
  // repair incomplete error object (eg chromium v77)
  return new Error(lastError.message);
};
