import {
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const CreateWallet = () => {
  // const [showSeedPhraseForm, setShowSeedPhraseForm] = useState<boolean>(false);
  // const [seedPhrase, setSeedPhrase] = useState("");
  // const [formError, setFormError] = useState<string>("");
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const steps = getSteps();

  // const { recoverWallet, createNewWallet } = useSatchel();

  // const handleSubmit = useCallback(
  //   async _event => {
  //     try {
  //       await recoverWallet(seedPhrase);
  //     } catch (e) {
  //       console.log(e);
  //       setFormError(e.message);
  //     }
  //   },
  //   [recoverWallet, seedPhrase]
  // );
  // const toggleSeedPhraseForm = useCallback(
  //   () => setShowSeedPhraseForm(x => !x),
  //   []
  // );

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <h1 className={classes.title}>Let's create your wallet</h1>

      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length && (
        <div className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Open your wallet
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default CreateWallet;

//
// Utils
//

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(4)
  },
  title: {
    marginTop: 0
  },
  stepper: {
    padding: 0
  },
  seedPhraseInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  submitButtons: {
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1)
    },
    textAlign: "right"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

// import React from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import StepContent from '@material-ui/core/StepContent';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

function getSteps() {
  return ["Select campaign settings", "Create an ad group", "Create an ad"];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`;
    case 1:
      return "An ad group contains one or more ads which target a shared set of keywords.";
    case 2:
      return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
    default:
      return "Unknown step";
  }
}

// export default function VerticalLinearStepper() {
// const classes = useStyles();
// const [activeStep, setActiveStep] = React.useState(0);
// const steps = getSteps();

// const handleNext = () => {
//   setActiveStep(prevActiveStep => prevActiveStep + 1);
// };

// const handleBack = () => {
//   setActiveStep(prevActiveStep => prevActiveStep - 1);
// };

// const handleReset = () => {
//   setActiveStep(0);
// };

// return (
//   <div className={classes.root}>
//     <Stepper activeStep={activeStep} orientation="vertical">
//       {steps.map((label, index) => (
//         <Step key={label}>
//           <StepLabel>{label}</StepLabel>
//           <StepContent>
//             <Typography>{getStepContent(index)}</Typography>
//             <div className={classes.actionsContainer}>
//               <div>
//                 <Button
//                   disabled={activeStep === 0}
//                   onClick={handleBack}
//                   className={classes.button}
//                 >
//                   Back
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleNext}
//                   className={classes.button}
//                 >
//                   {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//                 </Button>
//               </div>
//             </div>
//           </StepContent>
//         </Step>
//       ))}
//     </Stepper>
//     {activeStep === steps.length && (
//       <Paper square elevation={0} className={classes.resetContainer}>
//         <Typography>All steps completed - you&apos;re finished</Typography>
//         <Button onClick={handleReset} className={classes.button}>
//           Reset
//         </Button>
//       </Paper>
//     )}
//   </div>
// );
// }
