import * as extension from "extensionizer";
import React, { useCallback, useContext, useMemo } from "react";
import { EnvironmentType } from "../../enums";
import { checkForError, getEnvironmentType } from "../../utils/utils";

type ContextValue = {
  reload: () => void;
  openWindow: (config: { url: string }) => void;
  closeCurrentWindow: () => Promise<void>;
  getVersion: () => string;
  openExtensionInBrowser: (
    route?: string | null,
    queryString?: string | null
  ) => void;
  getPlatformInfo: () => void;
  currentTab: () => Promise<Error | browser.tabs.Tab>;
  switchToTab: (tabId: string) => Promise<Error | browser.tabs.Tab>;
  closeTab: () => Promise<Error>;
  showNotification: () => void;
};

const ExtensionContext = React.createContext<ContextValue | undefined>(
  undefined
);

const ExtensionProvider = (props: any) => {
  const reload = (): void => {
    extension.runtime.reload();
  };

  const openWindow = ({ url }: { url: string }): void => {
    extension.tabs.create({ url });
  };

  const closeCurrentWindow = (): Promise<void> => {
    return extension.windows.getCurrent((windowDetails: { id: string }) => {
      return extension.windows.remove(windowDetails.id);
    });
  };

  const getVersion = (): string => {
    return extension.runtime.getManifest().version;
  };

  const openExtensionInBrowser = useCallback(
    (route: string | null = null, queryString: string | null = null): void => {
      let extensionURL = extension.runtime.getURL("home.html");

      if (queryString) {
        extensionURL += `?${queryString}`;
      }

      if (route) {
        extensionURL += `#${route}`;
      }

      openWindow({ url: extensionURL });
      if (
        getEnvironmentType() !== EnvironmentType.ENVIRONMENT_TYPE_BACKGROUND
      ) {
        window.close();
      }
    },
    []
  );

  const getPlatformInfo = (cb: Function): void => {
    try {
      extension.runtime.getPlatformInfo((platform: any) => {
        cb(null, platform);
      });
    } catch (e) {
      cb(e);
    }
  };

  const currentTab = (): Promise<Error | browser.tabs.Tab> => {
    return new Promise((resolve, reject) => {
      extension.tabs.getCurrent((tab: browser.tabs.Tab) => {
        const err = checkForError();
        if (err) {
          reject(err);
        } else {
          resolve(tab);
        }
      });
    });
  };

  const switchToTab = (tabId: string): Promise<Error | browser.tabs.Tab> => {
    return new Promise((resolve, reject) => {
      extension.tabs.update(
        tabId,
        { highlighted: true },
        (tab: browser.tabs.Tab) => {
          const err = checkForError();
          if (err) {
            reject(err);
          } else {
            resolve(tab);
          }
        }
      );
    });
  };

  const closeTab = (tabId: string): Promise<Error> => {
    return new Promise((resolve, reject) => {
      extension.tabs.remove(tabId, () => {
        const err = checkForError();
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  };

  const showNotification = (
    title: string,
    message: string,
    url: string
  ): void => {
    extension.notifications.create(url, {
      type: "basic",
      title: title,
      iconUrl: extension.extension.getURL("../../images/icon-64.png"),
      message: message
    });
  };

  const value = useMemo(
    () => ({
      reload,
      openWindow,
      closeCurrentWindow,
      getVersion,
      openExtensionInBrowser,
      getPlatformInfo,
      currentTab,
      switchToTab,
      closeTab,
      showNotification
    }),
    [openExtensionInBrowser]
  );

  return <ExtensionContext.Provider value={value} {...props} />;
};

const useExtension = (): ContextValue => {
  const context = useContext(ExtensionContext);
  if (context === undefined) {
    throw new Error("useExtension must be used within an ExtensionProvider");
  }
  return context;
};

export { ExtensionProvider, useExtension };
